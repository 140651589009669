import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import "./styling/{publishersYaml.slug}.css";

export default function Component({ data, pageContext, location }) {
  const {
    publishersYaml: { name, latestPage },
  } = data;
  const { locale } = pageContext;
  const { pathname } = location;

  return (
    <div className="publisherWrapper">
      <Layout display="true" path={pathname} title={name}>
        <iframe className="publishersIFrame" src={latestPage}></iframe>
      </Layout>
    </div>
  );
}

export const query = graphql`
  query($id: String!) {
    publishersYaml(id: { eq: $id }) {
      name
      picture
      latestPage
    }
  }
`;
